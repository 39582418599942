:root {
  --ms-primary-color: #ff374c;
  --ms-primary-color-darker: #ce2537;
  --ms-primary-color-darkest: #a2101f;
  --ms-secondary-color: #1c2133;
}

html, body {
  margin: 0;
  padding: 0;
}

/* Image Picker */

img.user-image-selection {
  max-width: 150px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  margin-right: 15px;
}

img.user-image-option {
  max-width: 100%;
  border-radius: 50%;
  cursor: pointer;
  transition: .15s all;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

img.user-image-option:active {
  transform: scale(0.95);
  box-shadow: 0 0 0px rgb(0 0 0 / 20%);
}